module.exports = function planCombiner(products, plans) {
  if (!products || !plans) {
    return [];
  }

  const productsWithThePlans = products?.map((category) => ({
    ...category,
    subcategories: category?.subcategories?.map((sub) => ({
      ...sub,
      plans: sub?.plans?.map((plan) => {
        const planData = plans?.find((p) => p.id === plan.id);
        return planData;
      }),
    })),
  }));

  return productsWithThePlans;
};
